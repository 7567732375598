
import { ResponseSuccessTotal } from "../axiosClient";
import axiosClientUser, { ResponsePostSuccess, ResponseSuccess } from "../axiosClientUser";


export interface DataTypeUpDown {
    key: number,
    stt: number,
    id: number | null,
    name: string,
    type: number | null,
    size: number | null,
    file: string | ArrayBuffer,
    date_upload: string,
    checked: boolean
}

export interface TypeUpDown {
    file: string | ArrayBuffer,
    name: string,
    checked: boolean
}

export interface DataTypeVersionUpdate {
    key: number,
    id: number | null,
    version: string | null,
    type_vn: string,
    type_us: string,
    name_vn: string,
    name_us: string,
    file_vn: any,
    file_us: any,
}

export interface DataTypeVersionUpdate1 {
    key: number,
    id: number | null,
    version: string | null,
    type_vn: string | null,
    type_us: string | null,
    name_vn: string,
    name_us: string,
    file_vn: any,
    file_us: any,
}

export interface DocumentForm {
    tieuDe: string;
    ngayBatDau: string;
    ngayKetThuc?: string;
    khoi_nghiep_vu: number | null;
    soTaiLieu?: string;
    maTaiLieu?: string;
    loaiTaiLieu: number;
    danhMucTaiLieu: number;
    donViBH: number;
    lanBanHanh?: number | null;
    nguoiKy: string;
    moTa: string;
    baoMat: boolean;
    status: boolean;
    send_mail: boolean
    chu_the_ban_hanh: number | null;
    quyen_tai_lieu: QuyenTaiLieu[]
}

export interface DocumentUpdateForm {
    id: number;
    tieuDe: string;
    ngayBatDau: string;
    ngayKetThuc?: string;
    soTaiLieu?: string;
    maTaiLieu?: string;
    chu_the_ban_hanh: number | null;
    khoi_nghiep_vu: number | null;
    loaiTaiLieu: number | null;
    danhMucTaiLieu: number | null;
    donViBH: number | null;
    lanBanHanh?: number | null;
    nguoiKy: string;
    moTa: string;
    baoMat: boolean;
    status: boolean;
    send_mail: boolean;
    quyen_tai_lieu: QuyenTaiLieu[],
    tailen: TypeUpDown[]
}

export interface ListVersion {
    id?: number | null
    name_vn: string,
    file_vn: string,
    name_us: string,
    file_us: string,
}

export interface QuyenTaiLieu {
    admin_id: number,
}

export interface SelectOption {
    value: number;
    label: string;
}

export interface Pagination {
    current: number,
    pageSize: number,
    total: number,
}
export interface DataTypeDocument {
    key: number;
    stt: number;
    ten_tai_lieu: string;
    so_tai_lieu: string;
    ma_tai_lieu: string;
    danh_muc: string;
    don_vi_BH: string;
    ngay_hieu_luc: string;
    nguoi_ky: string;
    tinh_trang: number;
    tai_lieu_goc: string;
    ngay_tai_len: string;
    ma_danh_muc_tai_lieu: string
    ma_don_vi: string,
    admin_id: number
    loai_phong_ban: string
    username: string
    view: boolean
    chu_the_ban_hanh: string
}

export interface Document {
    id: number
    tieu_de: string
    so_tai_lieu: string
    ma_tai_lieu: string
    ngay_bat_dau: string
    ngay_ket_thuc_format: string
    ngay_ket_thuc: string
    nguoi_ky: string
    ngay_tai_len_format: string
    tai_lieu_goc: string
    loaiTaiLieu: DanhMucTaiLieu | null
    danhMucTaiLieu: DanhMucTaiLieu | null
    chuTheBanHanh: ChuTheBanHanh | null
    donViBanHanh: DonViBanHanh | null
    status: boolean
    adminID: AdminID | null
    admin_id: number
    loaiPhongBanTL: PhongBan | null
    quyen_tai_lieu: loaiTBTL[]
    tailieutailen: TLTL[]
}

export interface ChuTheBanHanh {
    id: number
    ten_don_vi_ban_hanh: string
}

export interface TLTL {
    id: number
    ten: string
}

export interface loaiTBTL {
    id: number
    view: boolean
}

export interface AdminID {
    id: number
    username: string
    fullname: string
}

export interface DocumentType {
    id: number
    ten_danh_muc: string
    ma_danh_muc: string
}

export interface DonViBanHanh {
    id: number
    ten_don_vi: string
    ma_don_vi: string
    loaiPhongBan: PhongBan | null
}

export interface PhongBan {
    id: number
    ten_loai_phong_ban: string
}

export interface DataTypeVersion {
    key: string,
    stt: number,
    type_vn: string,
    type_us: string,
    name_vn: string,
    name_us: string,
    file_vn: any,
    file_us: any,
}

export interface DataTaiLieu {
    id: number | null
    key: number,
    name: string,
    type: number | null,
    size: number | null,
    file: any,
}



export interface DataTypeDecentrailization {
    key: string;
    stt: number;
    don_vi: number | null;
    level: number | null;
    phong_ban: string;
    ten_tai_khoan: string;
    ho_va_ten: string;
    chuc_vu: string;
    code: string;
    chia_se: boolean;
    admin_id: number
    khoi_nghiep_vu: string
}


export interface ErrorDocument {
    tai_lieu: string,
    tieu_de: string,
    ngay_bat_dau: string,
    ngay_ket_thuc: string,
    so_tai_lieu: string,
    ma_tai_lieu: string,
    loai_tai_lieu: string,
    don_vi_ban_hanh: string,
    lan_ban_hanh: string,
    nguoi_ky: string,
    mota: string,
}

export interface DonViBH {
    id: number
    ten_don_vi_ban_hanh: string
    ma_don_vi_ban_hanh: string
    status: boolean
    don_vi: DonViBHCon
}
export interface DonViBHCon {
    id: number
    ma_don_vi: string
    ten_don_vi: string
    parent_id: any
    status: boolean
    delf: boolean
}

export interface DanhMucTaiLieu {
    id: number
    ten_danh_muc: string
    ma_danh_muc: string
}

export interface LoaiTaiLieu {
    id: number
    ten_danh_muc: string
    ma_danh_muc: string
}

export interface SoLuongFile {
    data_file: DataFile[]
    tong_dung_luong: number
}

export interface DataFile {
    id: number,
    loai_file: string,
    so_luong: number,
    dung_luong: number,
    image: string,
    check: boolean
}

//   Chi tiet Tai Lieu
export interface ChiTietTaiLieu {
    id: number
    tieu_de: string
    tai_lieu_goc: string | null
    ngay_bat_dau: string
    ngay_ket_thuc: string | null
    so_tai_lieu: string
    ma_tai_lieu: string
    loai_tai_lieu: number
    don_vi_ban_hanh: number
    lan_ban_hanh: number
    nguoi_ky: string
    mota: string
    bao_mat: boolean
    ngay_tai_len: string
    status: boolean
    send_mail: boolean
    admin_id: number
    delf: boolean
    khoi_nghiep_vu: number | null
    tailieutailen: ChiTietTaiLieuTaiLen[]
    phienbantailieu: ChiTietPhienBanTaiLieu[]
    donViBanHanh: ChiTietDonViBanHanh | null
    quyen_tai_lieu: ChiTietQuyenTaiLieu[]
    ngay_ket_thuc_format: string
    loaiTaiLieu: ChiTietLoaiTaiLieu | null
    danhMucTaiLieu: ChiTietLoaiTaiLieu | null
    chuTheBanHanh: ChiTietChuTheBanHanh | null
    tailieuDV: TaiLieuDV[]
    loai_phong_ban: number | null
    chu_the_ban_hanh: number | null
    loaiPhongBanTL: PhongBan | null
    adminID: AdminID
}

export interface TaiLieuDV {
    id: number
    donvi_id: number | null
    khoi_nghiep_vu: number | null
}

export interface ChiTietChuTheBanHanh {
    id: number
    ten_don_vi_ban_hanh: string
    ma_don_vi_ban_hanh: string
}


export interface ChiTietTaiLieuTaiLen {
    id: number
    key: number
    ten: string
    kich_thuoc: number
    loai_file: number
    ngay_tai_len: string
    tailieu_id: number
    delf: boolean
    loaiFile: LoaiFile | null
}

export interface LoaiFile {
    id: number
    loai_file: string
    color: string
    image: string
    file: string
}

export interface ChiTietPhienBanTaiLieu {
    id: number
    phien_ban: string
    tailieu_vn: string
    kich_thuoc_vn: number
    loai_file_vn: number
    tailieu_us: string
    kich_thuoc_us: number
    loai_file_us: number
    tailieu_id: number
    delf: boolean
    loaiFileVN: LoaiFileVn
    loaiFileUS: LoaiFileUs
}

export interface LoaiFileVn {
    id: number
    loai_file: string
    color: string
    image: string
}

export interface LoaiFileUs {
    id: number
    loai_file: string
    color: string
    image: string
}

export interface ChiTietDonViBanHanh {
    id: number
    ten_don_vi: string
    ma_don_vi: string
}

export interface ChiTietQuyenTaiLieu {
    id: number
    admin_id: number
}

export interface ChiTietLoaiTaiLieu {
    id: number
    ten_danh_muc: string
    ma_danh_muc: string
    status: boolean
    delf: boolean
}

export interface DataNguoiDungPhanQuyen {
    id: number
    username: string
    fullname: string
    chucvu: string
    code: string
    don_vi_admin: DonViAdmin
    phong_ban_admin: PhongBanAdmin | null
    nhom_quyen_admin: NhomQuyenAdmin | null
    admin_dv: AdminDV[]
}

export interface NhomQuyenAdmin {
    id: number
    ten_nhom_quyen: string
}

export interface AdminDV {
    id: number
    donvi_id: number
    admin_don_vi: AdminDonVi
}

export interface AdminDonVi {
    ten_don_vi: string
    level: number
    loaiPhongBan: PhongBanAdmin | null
}


export interface PhongBanAdmin {
    id: number
    ten_loai_phong_ban: string
}

export interface DonViAdmin extends DonVi {
    don_vi?: DonVi
}

export interface DonVi {
    id: number
    ma_don_vi: string
    ten_don_vi: string
}

export interface DataOption {
    value: number,
    label: string
}

export const TinhTrang: SelectOption[] = [
    {
        label: "Còn hiệu lực",
        value: 1
    },
    {
        label: "Hết hiệu lực",
        value: 0
    },
    {
        label: "Dự thảo",
        value: 2
    },
]


export const detailTaiLieu = async (id: number) => {
    const url = `tailieu/chi-tiet`;
    const params = { id }
    const result: ResponseSuccess<ChiTietTaiLieu> = await axiosClientUser.get(url, { params });
    return result;
}

export const downloadTaiLieuGoc = async (id: number) => {
    const url = `tailieu/tai-xuong`;
    const params = { id }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}

export const downloadTaiLenTaiVe = async (id: number) => {
    const url = `tailieu/tai-xuong-tai-len`;
    const params = { id }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}


export const downloadPhienBanTaiLieu = async (id: number, type: number) => {
    const url = `tailieu/tai-xuong-phien-ban`;
    const params = { id, type }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}

export const getSoLuongFile = async () => {
    const url = `tailieu/so-luong-file`;
    const result: ResponseSuccess<SoLuongFile> = await axiosClientUser.get(
        url
    );
    return result;
}

export const getNguoiDungPhanQuyen = async (don_vi: number[], search: string, loai_phong_ban: number | null, admin_id?: number) => {
    const don_vi_id: string = JSON.stringify(don_vi);
    const url = `nguoidung/danh-sach-phan-quyen`;
    const params = {
        don_vi_id,
        search,
        loai_phong_ban: loai_phong_ban === null ? "" : loai_phong_ban,
        admin_id
    }
    const result: ResponseSuccess<DataNguoiDungPhanQuyen[]> = await axiosClientUser.get(
        url, { params }
    );
    return result;
}

export const getNguoiDungPhanQuyenAll = async (don_vi: number[], search: string, loai_phong_ban: number | null, admin_id?: number) => {
    const don_vi_id: string = JSON.stringify(don_vi);
    const url = `nguoidung/danh-sach-phan-quyen-tat-ca`;
    const params = {
        don_vi_id,
        search,
        loai_phong_ban: loai_phong_ban === null ? "" : loai_phong_ban,
        admin_id
    }
    const result: ResponseSuccess<DataNguoiDungPhanQuyen[]> = await axiosClientUser.get(
        url, { params }
    );
    return result;
}

export const getLoaiTaiLieu = async () => {
    const url = `loaitailieu/danh-sach-tat-ca`;
    const result: ResponseSuccess<LoaiTaiLieu[]> = await axiosClientUser.get(
        url
    );
    return result;
}

export const getToanBoLoaiTaiLieu = async () => {
    const url = `loaitailieu/danh-sach-toan-bo`;
    const result: ResponseSuccess<LoaiTaiLieu[]> = await axiosClientUser.get(
        url
    );
    return result;
}


export const getDonViBH = async () => {
    const url = `donvibanhanh/danh-sach-tat-ca`;
    const result: ResponseSuccess<DonViBH[]> = await axiosClientUser.get(
        url
    );
    return result;
}

export function isValidUri(uri: string) {
    try {
        new URL(uri);
        return true;
    } catch (error) {
        return false;
    }
}


export const updateDocument = async (
    id: number,
    tieu_de: string,
    ngay_bat_dau: string,
    ngay_ket_thuc: string,
    so_tai_lieu: string,
    ma_tai_lieu: string,
    loai_tai_lieu: number | null,
    danh_muc_tai_lieu: number | null,
    don_vi_ban_hanh: number | null,
    lan_ban_hanh: number | null,
    nguoi_ky: string,
    mota: string,
    bao_mat: boolean,
    status: boolean,
    send_mail: boolean,
    quyen_tai_lieu: Array<any> = [],
    data_tai_lieu: Array<any> = [],
    tailieuDV: Array<any>,
    loai_phong_ban: number | null,
    khoi_nghiep_vu: number | null,
    chu_the_ban_hanh: number | null

) => {
    const url = `tailieu/cap-nhat`;
    const params = {
        id,
        tieu_de,
        ngay_bat_dau,
        ngay_ket_thuc,
        so_tai_lieu,
        ma_tai_lieu,
        loai_tai_lieu,
        danh_muc_tai_lieu,
        don_vi_ban_hanh,
        lan_ban_hanh,
        nguoi_ky,
        mota,
        bao_mat,
        status,
        send_mail,
        quyen_tai_lieu,
        data_tai_lieu,
        tailieuDV,
        loai_phong_ban,
        khoi_nghiep_vu,
        chu_the_ban_hanh
    }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}


export const createDocument = async (
    tieu_de: string,
    ngay_bat_dau: string,
    ngay_ket_thuc: string,
    so_tai_lieu: string,
    ma_tai_lieu: string,
    loai_tai_lieu: number | null,
    danh_muc_tai_lieu: number | null,
    don_vi_ban_hanh: number | null,
    lan_ban_hanh: number | null,
    nguoi_ky: string,
    mota: string,
    bao_mat: boolean,
    status: boolean,
    send_mail: boolean,
    quyen_tai_lieu: Array<any> = [],
    tailieuDV: Array<any>,
    loai_phong_ban: number | null,
    chu_the_ban_hanh: number | null,
    khoi_nghiep_vu: number | null,
    data_tai_lieu: Array<any> = [],
) => {
    const url = `tailieu/them-moi`;
    const params = {
        tieu_de,
        ngay_bat_dau,
        ngay_ket_thuc,
        so_tai_lieu,
        ma_tai_lieu,
        loai_tai_lieu,
        danh_muc_tai_lieu,
        don_vi_ban_hanh,
        lan_ban_hanh: lan_ban_hanh === 0 ? null : lan_ban_hanh,
        nguoi_ky,
        mota,
        bao_mat,
        status,
        send_mail,
        quyen_tai_lieu,
        tailieuDV,
        loai_phong_ban,
        chu_the_ban_hanh,
        khoi_nghiep_vu,
        data_tai_lieu
    }
    const result: ResponsePostSuccess = await axiosClientUser.post(
        url, params
    );
    return result;
}

export const deleteDocumentList = async (id: number) => {
    const url = `tailieu/xoa`;
    const params = { id }
    const result: ResponseSuccess<[]> = await axiosClientUser.delete(
        url, { params }
    );
    return result;
}

export const deleteVersion = async (id: number, type: number) => {
    const url = `tailieu/xoa-phien-ban`;
    const params = { id, type }
    const result: ResponseSuccess<[]> = await axiosClientUser.delete(
        url, { params }
    );
    return result;
}

export const deleteDocumentUpDown = async (id: number) => {
    const url = `tailieu/xoa-tai-len-tai-ve`;
    const params = { id }
    const result: ResponseSuccess<[]> = await axiosClientUser.delete(
        url, { params }
    );
    return result;
}

export const getDocumentList = async (PageNum: number, PageSize: number, search: string, loai_tai_lieu: number[], ngay_bat_dau: string, ngay_ket_thuc: string, tinh_trang: number | null, ngay_hieu_luc: string | null, don_vi_ban_hanh: number[], chu_the_ban_hanh: number[], don_vi: number | null, loai_phong_ban: number | null, phong_ban: number | null, danh_muc: number[]) => {
    const url = `tailieu/danh-sach`;
    const loaiTaiLieu: string = JSON.stringify(loai_tai_lieu);
    const donViBH: string = JSON.stringify(don_vi_ban_hanh);
    const chuTheBH: string = JSON.stringify(chu_the_ban_hanh);
    const danh_muc_tai_lieu: string = JSON.stringify(danh_muc);

    const params = { PageNum, PageSize, search, ngay_bat_dau, ngay_ket_thuc, loai_tai_lieu: loaiTaiLieu, don_vi_ban_hanh: donViBH, ngay_hieu_luc: ngay_hieu_luc === null ? "" : ngay_hieu_luc, tinh_trang: tinh_trang === null ? "" : tinh_trang, chu_the_ban_hanh: chuTheBH, don_vi, loai_phong_ban, phong_ban, danh_muc_tai_lieu }
    const result: ResponseSuccessTotal<Document> = await axiosClientUser.get(
        url, { params }
    );
    return result;
}

export const getDocumentListAll = async (search: string, loai_tai_lieu: number[], ngay_bat_dau: string, ngay_ket_thuc: string, tinh_trang: number | null, ngay_hieu_luc: string | null, don_vi_ban_hanh: number[], chu_the_ban_hanh: number[], don_vi: number | null, loai_phong_ban: number | null, phong_ban: number | null) => {
    const url = `tailieu/danh-sach-tat-ca`;
    const loaiTaiLieu: string = JSON.stringify(loai_tai_lieu);
    const donViBH: string = JSON.stringify(don_vi_ban_hanh);
    const chuTheBH: string = JSON.stringify(chu_the_ban_hanh);

    const params = { search, ngay_bat_dau, ngay_ket_thuc, loai_tai_lieu: loaiTaiLieu, don_vi_ban_hanh: donViBH, ngay_hieu_luc: ngay_hieu_luc === null ? "" : ngay_hieu_luc, tinh_trang: tinh_trang === null ? "" : tinh_trang, chu_the_ban_hanh: chuTheBH, don_vi, loai_phong_ban, phong_ban }
    const result: ResponseSuccess<Document[]> = await axiosClientUser.get(
        url, { params }
    );
    return result;
}
