
import { Breadcrumb } from 'antd';
import React from 'react';
import './styles.css'
import { useSelector } from 'react-redux';
import { selectorPermission } from '../store/slices/userPermission';

interface BreadcrumbInterface {
    children: string,
    title: string,
}

export const BreadcrumbComponent: React.FC<BreadcrumbInterface> = ({ children, title }) => {
    const data_menu = useSelector(selectorPermission)
    return (
        <div className="fix-breadcrumb-wrapper flex flex-col gap-3 bg-white px-[32px] py-4 h-[96px] justify-center breadcrumb-wrapper custom-breadcrumb">
            <Breadcrumb className=''
                items={[
                    {
                        title: <a href={`${data_menu.permission[0] ? data_menu.permission[0] : '/chart'}`} className=' text-[#8E8E8E] text-sm' >Blue DRM</a>,
                    },
                    {
                        title: <a href={`${data_menu.permission[0] ? data_menu.permission[0] : '/chart'}`} className=' text-[#8E8E8E] text-sm' >...</a>,
                    },
                    {
                        title: <span className=' text-[#4B4B4B] text-sm active' >{children}</span>,
                    },
                ]}
            />
            <h3 className='text-xl text-[#4B4B4B] font-[500] m-0 fix-text'>{title}</h3>
        </div>
    )
}
