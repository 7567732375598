import {
  MenuUnfoldOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu, MenuProps } from "antd";
import { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import './styles.css';
import {
  MenuFoldOutlined
} from '@ant-design/icons';
import { Thaco_logo } from '../components/imgExport';
import tokenService from "../service/token";
import { MenuApi } from '../api/LoginApi/loginApi';
import PersonalAdmin from './PersonalAdmin';
import { useSelector } from 'react-redux';
import { selectorPermission } from '../store/slices/userPermission';

export default function LayOut() {
  const { Header, Sider, Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  type MenuItem = Required<MenuProps>['items'][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }
  const [items, setItems] = useState<MenuItem[]>([]);
  const [rootSubmenuKeys, setRootSubmenuKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState(['sub0']);
  const [menu, setMenu] = useState<MenuApi[]>([])
  const data_menu = useSelector(selectorPermission)
  const getLeftMenu = async () => {
    const result = await tokenService.getMenu();
    if (result != null) {
      const data: MenuApi[] = JSON.parse(result ?? '')
      setMenu(data);
      let leftmenu: MenuItem[] = [];
      let rootSub: string[] = [];
      data.filter((value) => value.type === false).map((item, i) => {
        if (item.truy_cap) {
          let childrensd: MenuItem[] = [];
          item?.menu_con.sort((x, y) => x.id - y.id);
          if (item.menu_con && item?.menu_con.length > 0) {
            item?.menu_con.map((children) => {
              if (children.truy_cap) {
                let childrenMenu1: MenuItem[] = [];
                children.sub_menu.map((row) => {
                  let childrenMenu = getItem(
                    <Link to={row.sub_menu_url}>
                      {row.name}
                    </Link>,
                    row.id,
                  );
                  childrenMenu1.push({
                    ...childrenMenu,
                    key: row.id + 100
                  })
                })
                let childrenMenu = children.sub_menu && children.sub_menu.length > 0 ? getItem(
                  `${children.name}`,
                  children.id,
                  null,
                  childrenMenu1
                ) : getItem(
                  <Link to={children.menu_url}>
                    {children.name}
                  </Link>,
                  children.id,
                );
                childrensd.push(childrenMenu);
              }
            });
          }
          let mainMenu =
            item.menu_con && item.menu_con.length > 0
              ? getItem(
                `${item.name}`,
                `sub${i}`,
                <i className={`${item.icon}`} style={{ fontSize: 16 }}></i>,
                childrensd
              )
              : getItem(
                <Link
                  to={item.menu_url ? item.menu_url : ""}
                >
                  {item.name}
                </Link>,
                `sub${i}`,
                <i className={`${item.icon}`} style={{ fontSize: 16 }}></i>
              );
          rootSub.push(`sub${i}`);
          leftmenu.push(mainMenu);
        }
      });
      setItems(leftmenu);
      setRootSubmenuKeys(rootSub);
    }
  };

  useEffect(() => {
    getLeftMenu();
  }, []);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <div>
      <Layout className='w-full h-[100vh] overflow-hidden m-0 bg-[#F5F6FA]'>
        <Header className='flex w-full justify-between items-center pl-0 pr-6 bg-[#0038FF] relative m-0'>
          <div className="flex items-center gap-3 px-3">
            <Link to={`${menu.filter((value) => value.type === false)[0] ? menu.filter((value) => value.type === false)[0].menu_url : '/chart'}`} className='p-0 m-0 '>
              <div className="h-[63px] flex items-center justify-center z-20">
                <img width={191} src={Thaco_logo} className=' object-cover z-10' alt="" />
              </div>
            </Link>
            <div className="disabled sm-block">
              {/* menu */}
              <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                <Button
                  type="text"
                  className='text-white custom-button-color flex items-center justify-center '
                  icon={<MenuUnfoldOutlined />}
                />
              </Dropdown>
            </div>
            {/* <Link to='/chart' className='p-0 m-0'>
              <p className='h-[64px] text-dark flex items-center justify-center pl-3 disabled sm-block tracking-widest font-[800] uppercase'>Thaco</p>
            </Link> */}
            <Button
              type="text"
              className=' custom-button-color flex items-center justify-center sm-disabled text-white'
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
            />
          </div>
          <PersonalAdmin />
        </Header>
        <Layout >
          <Sider className='sm-disabled bg-white w-full' trigger={null} collapsible collapsed={collapsed}>
            <div className='min-h-[100vh] bg-white'>
              <div className="bg-white">
                <Menu
                  className='custom-menu-title text-li text-submenu-8892b0 text-item-8892b0'
                  openKeys={openKeys}
                  theme="light"
                  mode="inline"
                  onOpenChange={onOpenChange}
                  defaultSelectedKeys={['1']}
                  items={items}
                />
              </div>
            </div>
          </Sider>
          <Content
            className='h-full bg-[#F5F6FA] overflow-y-scroll w-full overflow-x-hidden'>
            <Outlet></Outlet>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
